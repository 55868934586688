<template>
  <div id="createTemplate">
    <div class="create-template">
      <mds-layout-grid class="view-template">
        <mds-row>
          <span>
            <router-link :to="{ name: getRouteDetails('/settings/templates') }">
              Templates
            </router-link>
          </span>
          <span class="view-template-router">
            > View Template: {{ templatename }}</span
          >
        </mds-row>
        <mds-row class="view-template-heading">
          View Template: {{ templatename }}
        </mds-row>

        <!-- template name and template type -->
        <mds-row class="view-template-name-and-dataset-name">
          <mds-col :cols="4">
            <mds-row class="view-template-name-heading">
              Templates Name
            </mds-row>
            <mds-row class="view-template-name" style="margin-top: 14px;">
              {{ templatename }}
            </mds-row>
          </mds-col>
          <mds-col :cols="4">
            <mds-row class="view-template-name-heading">
              Data Set
            </mds-row>
            <mds-row class="view-template-name" style="margin-top: 14px;">
              {{ DataSetName }}
            </mds-row>
          </mds-col>
        </mds-row>
        <!-- <mds-row class="view-template-border"></mds-row> -->
      </mds-layout-grid>
      <!--  -->
      <div style="border-bottom: 1px solid #cccccc;margin-top:25px"></div>

      <div class="template-list-main-section" style="margin-top:16px">
        <div v-if="templatesList.length">
          <template-tree-view
            :templatesList="templatesList"
            :node="templatesList"
          >
          </template-tree-view>
        </div>
      </div>
    </div>

    <loader-component v-if="showLoader"></loader-component>
    <notification-component
        v-if="showNotification"
        :notificationMessage="notificationMessage"
        :notificationType="notificationType"
    ></notification-component>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import templateTreeView from "./templateTreeView.vue";
import { getEditTemplateList } from "../../services/reporting_service.js";
import NotificationComponent from "../ui_component/notificationComponent.vue";

export default {
  name: "reportingcreatetemplate",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    LoaderComponent,
    templateTreeView,
    NotificationComponent
  },

  data() {
    return {
      showNotification: false,
      toggle: false,
      disabled: false,
      toggleDialog: false,
      groupEntity: "",
      templatename: "",
      getDataSet: [],
      DataSetName: "",
      selectedAction: "",
      selectedTemplateListIds: [],
      showLoader: true,
      templatesList: [],
      dialogTitle: "",

      //JSON declaration for header action button
      counterDetails: {
        displayCounterDetails: true,
        count: 0,
        countText: "Selected",
        showVerticalLine: true,
      },
    };
  },

  computed: {
    ...mapGetters("reporting", ["getDataSets", "getTempDataPointList"]),
  },

  async mounted() {
    const templateId = await this.$route.query.templateId;
    if (templateId) {
      const response = await getEditTemplateList(templateId);
      if (response.status == 200) {
        const editResponse = response.data;
        this.templatename = editResponse.templatename;
        this.DataSetName = editResponse.datasetname;
        this.disabled = true;
        this.getResponseOnPageLoad();
        const dataSetPoint = JSON.parse(response.data.filter);
        const template_dataList = dataSetPoint.template_datalist
          && JSON.parse(dataSetPoint.template_datalist);
        if (!template_dataList) {
          const dataSet =
            (dataSetPoint.data &&
              dataSetPoint.data.reduce((obj, item) => {
                if (typeof item.group === "object") {
                  const groupsList = [];

                  item.group.map((grpItem) => {
                    const group = {
                      checked: false,
                      name: grpItem.header_name,
                      id: grpItem.value.datapoint_id,
                    };
                    groupsList.push(group);
                  });
                  const result = {
                    name: item.header_name,
                    id: item.id,
                    datapoints: groupsList,
                  };
                  obj.push(result);
                }
                if (typeof item.group !== "object") {
                  const singleDatapoint = {
                    checked: false,
                    name: item.header_name,
                    id: item.value.datapoint_id,
                  };
                  obj.push(singleDatapoint);
                }
                return obj;
              }, [])) ||
            [];
          this.templatesList = dataSet;
        } else {
          this.templatesList = template_dataList;
        }
      }else{
            this.notificationType = "error";
            this.notificationKey = "error-default";
            this.notificationMessage = response.data.message;
            this.showNotification = true;
            this.showLoader = false;
            setTimeout(() => {
              this.showNotification = false;
              this.$router.push({ name: "Reporting Templates" });
            }, 2000)
        }
    } else {
      this.getResponseOnPageLoad();
    }
  },

  methods: {
    ...mapActions("reporting", [
      "fetchDataSetsList",
      "fetchTemplateDataPonitList",
    ]),
    ...mapMutations("reporting", ["setSelectedEntity", "saveSelectedEntity"]),

    groupIcon(event) {
      const groupblk = event.target.closest(".template-list");
      const caretIcon = event.target.closest(".caret");
      groupblk.classList.toggle("activeCol");
      caretIcon.classList.toggle("caret-down");
    },

    async getResponseOnPageLoad() {
      var obj = {
        Page: 1,
        pageSize: 0,
      };
      await this.fetchDataSetsList(obj);
      this.getDataSetResopnse();
      this.showLoader = false;
    },

    getDataSetResopnse() {
      const response = this.getDataSets;
      const dataSetList = response.dataset;
      this.getDataSet = dataSetList;
    },

    // Route to Reporting Universe page
    getRouteDetails(url) {
      const routeDetails = this.$router.resolve({
        path: url,
      });
      return routeDetails.resolved.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.view-template {
  padding: 0px 16px 0 16px;
}

.view-template-router {
  margin-left: $mds-space-half-x;
}

.view-template-heading {
  padding-top: $mds-space-2-x;
  margin-bottom: $mds-space-2-x;
  height: 36px;
  left: 16px;
  top: 50px;
  font-style: normal;
  font-weight: 250;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.view-template-name-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.view-template-name {
  margin-bottom: $mds-space-1-x;
}

.view-template-border {
  border-bottom: 1px solid #cccccc;
}
.view-template-name-and-dataset-name {
  padding-top: $mds-space-2-x;
}
// change
.create-template {
  padding: 16px 16px 0 16px;

  .template-list,
  .template_list_grpContent {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
  }
  .template-list_grpblk {
    width: 100%;
  }
  .caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
  .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  .caret-down::before {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);
  }
  .template-list.activeCol .template-list_group {
    display: block;
  }
  .template-list_group {
    display: none;
  }
  .template_list_grpTitle,
  .template-list-checkbox {
    display: flex;
    padding-bottom: 4px;
    margin-left: 5px;
  }
  .template_list_grpContent {
    margin-left: 28px;
    width: 96%;
  }
  .triangle_icon {
    cursor: pointer;
  }
  .template_list_grpTitle,
  .template_list_grpContent {
    cursor: move;
  }

  .template-sublist {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
  }
}
</style>
