<template>
    <div>
        <draggable v-model="mutateTemplatesList" @change="sortableData">
            <transition-group>
                <div class="template-list" v-for="(item, indexList) in mutateTemplatesList" :key="indexList">
                    <div class="template-list_grpblk">
                        <div class="template_list_grpTitle">
                            <div class="template-list-dragdrop-button">
                                <mds-button
                                    variation="icon-only"
                                    icon="grab-handle"
                                    type="button">
                                </mds-button>
                            </div>
                            <div class="template-list-checkbox">
                            <span
                                class="triangle_icon caret"
                                @click="groupIcon($event)"
                                v-if="item.datapoints">
                            </span>
                            <mds-checkbox
                                @change="$emit('changeTemplateCheckbox', item)"
                                :value="String(item.id)">
                                {{ templateNameWithLangCode(item) }}
                            </mds-checkbox>
                            </div>
                        </div>
                        <template-tree-child-view
                            v-if="item.datapoints"
                            @dataSetFormat="mutatedatapoints"
                            @changeTemplateCheckbox="changeTemplateCheckbox"
                            :listItems="item"
                            :datapoints="item.datapoints"></template-tree-child-view>
                    </div>
                </div>
            </transition-group>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { MdsButton } from "@mds/button";
import MdsCheckbox from "@mds/checkbox";
import templateTreeChildView from './templateTreeChildView.vue';

export default {
    name: 'template-tree-view',
    components: {
        templateTreeChildView,
        draggable,
        MdsButton,
        MdsCheckbox
    },
    data() {
        return {
            mutateTemplatesList: this.templatesList
        }
    },
    watch: {
        templatesList(n) {
            this.mutateTemplatesList = n;
        }
    },
    props: {
        node: Array,
        templatesList: {
            type: Array,
            default: () => ([]),
        },
    },
    methods: {
        changeTemplateCheckbox(item) {
            this.$emit('changeTemplateCheckbox', item);
        },
        mutatedatapoints() {
            // console.log(newData, 'newData');
            // this.$emit('dataSetFormat', newData);
            // console.log(this.mutateTemplatesList, 'this.mutateTemplatesList');
            this.$emit('dataSetFormat', this.mutateTemplatesList);
        },
        sortableData() {
            // const el = document.querySelector('.activeCol');
            // if (el && el.classList.contains('activeCol')) {
            //     el.classList.remove("activeCol");
            // }
            // console.log(this.mutateTemplatesList, 'mutateTemplatesList')
            this.$emit('dataSetFormat', this.mutateTemplatesList);
        },
        templateNameWithLangCode(item) {
            const name = item.name;
            const langcode = item.langcode && item.langcode.length && item.langcode[0].text;
            const languageName = langcode ? `(${langcode})` : '';
            return `${name} ${languageName}`;
        },
        groupIcon(event) {
            const groupblk = event.target.closest('.template-list');
            const caretIcon = event.target.closest('.caret');
            groupblk.classList.toggle("activeCol");
            caretIcon.classList.toggle("caret-down");
        },
    },
}
</script>

<style lang="scss" scoped>

.template-list,
.template-list_group .template_list_grpTitle {
    display:block;
    border:1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
}
.template-list_grpblk {
    width: 100%;
}
.caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}
.caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
}
.caret-down::before {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);  
}
.template-list.activeCol .template-list_group {
    display: block;
}
.template-list_group {
    display: none;
}
.template_list_grpTitle,
.template-list-checkbox {
    display: flex;
}
.template-list_group .template_list_grpTitle {
    margin-left: 28px;
    width: 96%;
}
.triangle_icon {
    margin-left: -2px;
    cursor: pointer;
}
.template_list_grpTitle,
.template-list_group .template_list_grpTitle {
    cursor: move;
}

.template-list-dragdrop-button{
    margin-right: 5px;
    display: inline-block;
}
</style>