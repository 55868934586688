<template>
    <div class="template-list_group">
        <draggable v-model="mutatedatapoints" @change="sortableData">
            <div class="template_list_grpTitle"
                v-for="(groupItem, index) in mutatedatapoints" :key="index">
                <div class="template-list-dragdrop-button">
                    <mds-button
                        variation="icon-only"
                        icon="grab-handle"
                        type="button">
                    </mds-button>
                </div>
                <div class="template-list-checkbox">
                    <!-- <span
                        class="triangle_icon caret"
                        @click="groupIcon($event)"
                        v-if="groupItem.datapoints">
                    </span> -->
                    <mds-checkbox
                        @change="$emit('changeTemplateCheckbox', groupItem)"
                        :value="String(groupItem.id)">
                        {{ groupItem.name }}
                    </mds-checkbox>
                </div>
                <div>
                    <template-tree-child-view
                        v-if="groupItem.datapoints"
                        @changeTemplateCheckbox="changeTemplateCheckbox"
                        :childListItem="groupItem"
                        :datapoints="groupItem.datapoints"></template-tree-child-view>
                
                <!-- <template-tree-grand-child-view
                    v-for="(itm, indx) in groupItem.datapoints"
                    :key="indx"
                    :level="level + 1"
                    @changeTemplateCheckbox="changeTemplateCheckbox"
                    :node="itm"
                    :datapoints="itm"></template-tree-grand-child-view> -->
                </div>
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { MdsButton } from "@mds/button";
import MdsCheckbox from "@mds/checkbox";

export default {
    name: 'template-tree-child-view',
    components: {
        draggable,
        MdsButton,
        MdsCheckbox
    },
    data() {
        return {
            templatesList: [],
            mutatedatapoints: this.datapoints
        }
    },
    props: {
        node: Array,
        datapoints: {
            type: Array,
            default: () => ([]),
        },
        listItems: {
            type: Object,
            default: () => ({}),
        },
        childListItem: {
            type: Object,
            default: () => ({}),
        },
        level: {
            type: Number,
            default: 0,
        }
        // node: Array
    },
    watch: {
        datapoints(n) {
            this.mutatedatapoints = n;
        }
    },
    methods: {
        changeTemplateCheckbox(item) {
            this.$emit('changeTemplateCheckbox', item);
        },
        sortableData() {
            // const el = document.querySelector('.activeCol');
            // if (el && el.classList.contains('activeCol')) {
            //     el.classList.remove("activeCol");
            // }
            this.listItems.datapoints = this.mutatedatapoints;
            if (Object.keys(this.childListItem).length) {
                this.childListItem.datapoints = this.mutatedatapoints;
            }
            // console.log(this.childListItem, 'this.childListItem')
            // console.log(this.listItems, 'listItems');
            // console.log(this.mutatedatapoints, 'this.mutatedatapoints');
            this.$emit('dataSetFormat', this.listItems)
        },
        templateNameWithLangCode(item) {
            const name = item.name;
            const langcode = item.langcode && item.langcode.length && item.langcode[0].text;
            const languageName = langcode ? `(${langcode})` : '';
            return `${name} ${languageName}`;
        },
        groupIcon(event) {
            const groupblk = event.target.closest('.template-list');
            const caretIcon = event.target.closest('.caret');
            groupblk.classList.toggle("activeCol");
            caretIcon.classList.toggle("caret-down");
        },
    },
}
</script>
<style lang="scss" scoped>

.template-list,
.template-list_group .template_list_grpTitle {
    display:block;
    border:1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
}
.template-list_grpblk {
    width: 100%;
}
.caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}
.caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
}
.caret-down::before {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);  
}
.template-list.activeCol .template-list_group {
    display: block;
}
.template-list_group {
    display: none;
}
.template_list_grpTitle,
.template-list-checkbox {
    display: flex;
}
.template-list_group .template_list_grpTitle {
    margin-left: 28px;
    width: 96%;
}
.triangle_icon {
    margin-left: -2px;
    cursor: pointer;
}
.template_list_grpTitle,
.template-list_group .template_list_grpTitle {
    cursor: move;
}

.template-list-dragdrop-button{
    margin-right: 5px;
    display: inline-block;
}
.template-list_group .template-list_group .template_list_grpTitle { 
    cursor: default;
}
// .template-list_group .template-list_group .template-list-dragdrop-button {
//     visibility: hidden;
// }
.template-list_group .template-list-checkbox {
    display: inline-block;
    position: relative;
    top: -4px;
}
</style>